import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { deleteServices, getAllServices } from "../../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../components/DrawerTemplate";
import EmptyScreen from "../../../components/EmptyScreen";
import { DeleteButton, EditButton } from "../../../components/IconButtons";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";
import { ServiceForm } from "./ServiceForm";

export const Services = () => {
  const queryClient = useQueryClient();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<any>(null);

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const { data, isLoading } = useQuery(
    [
      "services",
      {
        search: search,
        limit: limit,
        offset: limit * page,
      },
    ],
    getAllServices
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { mutate: deleteServicesMutate } = useMutation(deleteServices, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("services");
      toast.success(res?.data?.message);
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message);
    },
  });

  const handleEditClick = (data: any) => {
    setFormData(data);
    toggleDrawer(true);
  };
  const handleDeleteClick = (id: any) => {
    deleteServicesMutate(id);
  };

  const handleAdd = () => {
    toggleDrawer(true);
    setFormData(null);
  };

  const Columns = [
    {
      title: "Service Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Symptoms",
      dataIndex: "symptom",
      key: "symptom",
      render: (colData: any, row: any) => {
        const categories = row?.symptoms.map((i: any) => (
          <Box
            sx={{
              backgroundColor: "#1212121A",
              borderRadius: "15px",
              padding: "7px",
              marginTop: "2px",
              textAlign: "center",
              width: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {i?.title}
          </Box>
        ));

        const rows = [];
        for (let i = 0; i < categories?.length; i += 7) {
          rows.push(
            <Box
              key={i}
              sx={{
                display: "flex",
                gap: 2,
                marginBottom: "10px",
              }}
            >
              {categories.slice(i, i + 7)}
            </Box>
          );
        }

        return <div>{rows}</div>;
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (colData: any, row: any) => (
        <Box display="flex" gap="20px">
          <EditButton onClick={() => handleEditClick(row)} />
          <DeleteButton onClick={() => handleDeleteClick(colData)} />
        </Box>
      ),
    },
  ];

  return (
    <Box my={2}>
      <Box mb={2} display="flex" gap={2} alignItems="center">
        <Typography variant="h3">Service</Typography>
        {/* <Button variant="contained" onClick={handleAdd}>
          + Add New Service
        </Button> */}
      </Box>
      {/* <Box>
        <TextField
          label="Search by Service"
          sx={{ width: "450px", padding: "0px" }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box> */}
      <Box>
        {data?.data?.count === 0 ? (
          <EmptyScreen type="services" />
        ) : (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <Box mt={2}>
                <Table
                  key={"payouts"}
                  dataSource={data?.data?.services}
                  rowKey={"id"}
                  columns={Columns}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TablePagination
                    count={data?.data?.count}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
      <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
        <StyledDrawerHeader>
          <Typography> Add Services</Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <ServiceForm
            formData={formData}
            toggleDrawer={(e: any) => toggleDrawer(e)}
          />
        </StyledDrawerContent>
      </Drawer>
    </Box>
  );
};
